<script setup lang="ts">
import { useUserStore } from '~/stores/user';

const user = useSupabaseUser()
const userStore = useUserStore();

const dropdownItems = [
  [
    {
      slot: 'account',
      disabled: true,
    }
  ],
  [
    {
      label: 'Sign out',
      icon: 'i-lucide-log-out',
      click: async () => {
        await userStore.signOut();
      }
    }
  ]
]
</script>

<template>
  <div class="w-screen h-screen bg-gray-50 relative flex flex-col justify-center items-center">
    <div class="absolute top-3 right-3 flex justify-between items-center">
      <NuxtDropdown
        :items="dropdownItems"
        :popper="{
          placement: 'bottom-start',
        }"
        :ui="{
          width: 'w-52',
        }"
      >
        <NuxtAvatar 
          :src="user?.user_metadata?.avatar_url" 
          :alt="user?.user_metadata?.full_name"
        />
        <template #account>
          <div class="text-left">
            <p>
              {{ user.user_metadata?.full_name ?? 'Signed in as' }}
            </p>
            <p class="truncate font-medium text-gray-900">
              {{ user.user_metadata?.email }}
            </p>
          </div>
        </template>
        <template #item="{ item }">
          <span class="truncate">
            {{ item.label }}
          </span>
          <NuxtIcon 
            :name="item.icon" 
            class="flex-shrink-0 h-4 w-4 text-gray-400 ms-auto" 
          />
        </template>
      </NuxtDropdown>
    </div>
    <slot />
  </div>
</template>

<style scoped>

</style>